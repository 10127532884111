<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>提现列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <div style="margin-left:44px" class="page-content-search">
        <el-row :gutter="16">
          <el-col :span="4">
            <el-card shadow="hover">
              <div class="d-flex">
                <div>总金额</div>
                <div>{{ info ? info.cumulative_amount : "" }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card shadow="hover">
              <div class="d-flex">
                <div>可提现金额</div>
                <div>{{ info ? info.balance_amount : "" }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card shadow="hover">
              <div class="d-flex">
                <div>提现中金额</div>
                <div>{{ info ? info.sum_withdraw_amount : "" }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card shadow="hover">
              <div class="d-flex">
                <div>已提现金额</div>
                <div>{{ info ? info.withdrawn_amount : "" }}</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <!--搜索-->
      <div class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input v-model="search.order_no"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提现时间">
                <el-date-picker
                  v-model="search.add_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单状态">
                <el-select
                  v-model="search.settle_state"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option label="全部" value="all"></el-option>
                  <el-option label="结算中" :value="1"></el-option>
                  <el-option label="结算完成" :value="2"></el-option>
                  <el-option label="拒绝" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提现金额">
                <el-input v-model="search.withdrawal_money"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button size="medium" type="primary" @click="Withdrawal()">提现</el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table v-loading="loading" :data="tableData" border size="medium">
        <el-table-column
          width="120"
          prop="withdraw_no"
          label="提现单号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="120"
          prop="withdraw_time"
          label="提现时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="120"
          prop="state_time"
          label="审批时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.state_time != '1970-01-01 08:00:00'">{{
              scope.row.state_time
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="settle_state"
          label="提现状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.state === 1" type="success"
              >同意提现</el-tag
            >
            <el-tag size="mini" v-if="scope.row.state === 2" type="danger"
              >拒绝提现</el-tag
            >
            <el-tag size="mini" v-if="scope.row.state === 0" type="success"
              >提现中</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="withdraw_amount" label="提现金额" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="withdraw_before_amount"
          label="提现前可用"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="withdraw_after_amount"
          label="提现后剩余"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column width="120" prop="remark" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          width="120"
          prop="state_remark"
          label="审批备注"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="120"
          prop="bank_card_no"
          label="银行卡号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="120"
          prop="bank_card_name"
          label="开户行"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="120"
          prop="bank_of_deposit"
          label="开户行地址"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
      <!--提现-->
      <el-dialog
        v-loading="loading"
        title="银行卡"
        :visible.sync="editPage"
        width="50%"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-row>
          <el-form size="medium" ref="form" :label-width="this.env.label_width">
            <el-col :span="24">
              <el-form-item v-if="is_auth('systemset.company.applyexamine')">
                <el-link type="primary" @click="modifyCard">修改银行卡</el-link>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="银行卡名称">
                <el-input v-model="bank_card_name" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="银行卡号">
                <el-input v-model="bank_card_no" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="开户行地址">
                <el-input v-model="bank_of_deposit" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="输入金额">
                <el-input type="Number" v-model="bankinfo.withdrawal_money"></el-input>
                <span class="form-tip">单笔手续费1%。最大不超过50000</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  :maxlength="300"
                  v-model="bankinfo.remarks"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button
            v-if="is_auth('accountbook.withdrawal.issave')"
            size="medium"
            type="primary"
            @click="save"
            >确认提现</el-button
          >
          <el-button size="medium" @click="editPage = false">取 消</el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page_name: "提现列表",
      editPage: false,
      loading: true,
      bankinfo: {},
      bank_card_name: "",
      bank_card_no: "",
      bank_of_deposit: "",
      tableData: [], // 列表内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      info: {
        cumulative_amount: "",
        withdrawn_amount: "",
        balance_amount: "",
        sum_withdraw_amount: "",
      },
      search: {}, // 搜索内容
    };
  },
  // 创建
  created() {
    this.getbankinfo();
    this.init();
    this.getLeder();
  },
  // 安装
  mounted() {},
  methods: {
    // 初始化
    init() {
      this.search = {
        order_no: "",
        add_date: "",
        settle_state: "all",
        withdrawal_money: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 添加页面显示
    Withdrawal() {
      this.bankinfo = {
        withdrawal_money: 0,
        remarks: "",
      };
      this.editPage = true; // 显示页面
    },
    // 保存
    save() {
      let postdata = {
        api_name: "accountbook.withdrawal.addwithdraw",
        token: this.Tool.get_l_cache("token"),
        withdrawal_money: this.bankinfo.withdrawal_money,
      };
      Object.assign(postdata, this.bankinfo);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$alert("提现审批3-5个个工作日到账，请注意查看订单状态", "申请成功", {
            confirmButtonText: "确定",
            onClose: () => {
              this.editPage = false;
              this.getlist();
              this.getLeder();
            },
          });
        } else {
          this.$message.error(json.message);
        }
      });
    },
    //获取银行卡信息
    getbankinfo() {
      let postdata = {
        api_name: "accountbook.withdrawal.bankinfo",
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.bank_card_name = json.data.bank_card_name;
          this.bank_card_no = json.data.bank_card_no;
          this.bank_of_deposit = json.data.bank_of_deposit;
        } else {
          this.$message.error(json.message);
        }
      });
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "accountbook.withdrawal.getnewlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        this.info = json.info;
        if (json.code === 0) {
          console.log(json);
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.$message.error(json.message);
        }
      });
    },
    getLeder() {
      const postdata = {
        api_name: "accountbook.withdrawal.getledger",
        token: this.Tool.get_l_cache("token"),
      };
      this.Tool.post_data("merchant", postdata, (res) => {
        if (res.code === 0) {
          this.info = res.data;
        }
      });
    },
    modifyCard() {
      this.$router.push({ path: "/company/info" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
